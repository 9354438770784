/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import { GitHub, Mail, Linkedin, FileText } from "react-feather";
import styled from "styled-components";
import { PageLink, PageContent, PageWrapper } from "constants/Mixins";

const HomePage = () => {
	useEffect(() => {
		document.title = "Arav Watwani";
	}, []);

	return (
		<PageWrapper>
			<PageContent>
				<Header>
					<Name>Arav Watwani</Name>
					<Subtitle>Software Engineer</Subtitle>
					<Icons>
						<IconLink
							href={"https://github.com/aravwatwani"}
							target="_blank"
							rel="noopener noreferrer"
						>
							<GitHub />
						</IconLink>
						<IconLink
							href={"resume.pdf"}
							target="_blank"
							rel="noopener noreferrer"
						>
							<FileText />
						</IconLink>
						<IconLink
							href={"mailto:aravwatwani@gmail.com"}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Mail />
						</IconLink>
						<IconLink
							href={"https://linkedin.com/in/aravwatwani"}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Linkedin />
						</IconLink>
					</Icons>
				</Header>
				<Section>
					<SectionTitle>About</SectionTitle>

					<Description>
						Hello! I'm Arav—nice to meet you! I'm currently a
						software engineer at{" "}
						<PageLink
							href={"https://gusto.com"}
							target="_blank"
							rel="noopener noreferrer"
						>
							Gusto
						</PageLink>{" "}
						in San Francisco.
					</Description>
					<Description>
						I graduated from{" "}
						<PageLink
							href={"https://berkeley.edu"}
							target="_blank"
							rel="noopener noreferrer"
						>
							UC Berkeley
						</PageLink>{" "}
						with a degree in computer science. During my time there,
						I was an engineering intern at{" "}
						<PageLink
							href={"https://loop.com"}
							target="_blank"
							rel="noopener noreferrer"
						>
							Loop
						</PageLink>
						,{" "}
						<PageLink
							href={"https://imprint.co"}
							target="_blank"
							rel="noopener noreferrer"
						>
							Imprint
						</PageLink>
						, and{" "}
						<PageLink
							href={"https://joinattain.com"}
							target="_blank"
							rel="noopener noreferrer"
						>
							Attain
						</PageLink>
						, and was a part of the{" "}
						<PageLink
							href={"https://fellows.kleinerperkins.com/"}
							target="_blank"
							rel="noopener noreferrer"
						>
							Kleiner Perkins
						</PageLink>{" "}
						and{" "}
						<PageLink
							href={"https://8vc.com/fellows/arav-watwani"}
							target="_blank"
							rel="noopener noreferrer"
						>
							8VC
						</PageLink>{" "}
						fellowships.
					</Description>
					<Description>
						In my free time, I take lots of{" "}
						<PageLink
							href={"https://ocf.io/~aravwatwani"}
							target="_blank"
							rel="noopener noreferrer"
						>
							{" "}
							photos
						</PageLink>
						, try to make latte art, and watch Chelsea FC and
						Chicago sports.
					</Description>
				</Section>
				<Section>
					<PageLink href={"/writing"} rel="noopener noreferrer">
						<SectionTitle>Writing &#8594;</SectionTitle>
					</PageLink>
				</Section>

				<Section>
					<Footer>
						A work in progress. Last updated in{" "}
						<PageLink> June 2024</PageLink>.
					</Footer>
				</Section>
			</PageContent>
		</PageWrapper>
	);
};

export default HomePage;

const Header = styled.div`
	margin: 120px auto 70px auto;
`;

const Name = styled.div`
	font-weight: 600;
	font-size: 38px;
	margin-bottom: 8px;
	// color: ${({ theme }) => theme.black};
	color: #ff665a;
`;

const Subtitle = styled.div`
	font-weight: 450;
	font-size: 20px;
	margin-bottom: 40px;
	color: ${({ theme }) => theme.subtitle};
`;

const IconLink = styled(PageLink)`
	&:hover {
		background-size: 0;
		svg {
			fill: ${({ theme }) => theme.accent};
			transition: 0.2s all ease-in-out;
		}
	}
`;

const Icons = styled.div`
	display: inline-block;
	margin-right: 24px;

	svg {
		width: 22px;
		margin-right: 16px;
		stroke: ${({ theme }) => theme.subtitle};
	}
`;

const Description = styled.div<{ marginBottom?: number }>`
	line-height: 1.5;
	margin-bottom: ${({ marginBottom = 24 }) => marginBottom}px;
`;

const Footer = styled.div<{ marginBottom?: number }>`
	line-height: 1.5;
	margin-bottom: ${({ marginBottom = 24 }) => marginBottom}px;
	text-align: left;
	position: relative;
	font-size: 13px;
	color: ${({ theme }) => theme.subtitle};
`;

const Section = styled.div`
	margin-bottom: 70px;
`;

const SectionTitle = styled.div`
	display: inline-block;
	position: relative;
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 32px;

	&::after {
		content: "";
		background-color: ${({ theme }) => "#ffdcc7"};
		height: 8px;
		position: absolute;
		top: 60%;
		left: 0;
		width: 100%;
		z-index: -1;
		opacity: 1;
		transition: 0.2s all ease-in-out;
	}
`;
