import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import HomePage from "pages/HomePage";
import WritingPage from "pages/WritingPage";
import NotFoundPage from "pages/NotFoundPage";
import RiskAversion from "pages/writing/RiskAversion";
import ClarityAndDrive from "pages/writing/ClarityAndDrive";

const Routes = () => (
	<BrowserRouter basename="/">
		<Switch>
			<Route exact path="/" component={HomePage} />
			<Route exact path="/writing" component={WritingPage} />
			<Route
				exact
				path="/writing/risk-aversion"
				component={RiskAversion}
			/>
			<Route
				exact
				path="/writing/clarity-and-drive"
				component={ClarityAndDrive}
			/>
			<Route component={NotFoundPage} />
		</Switch>
	</BrowserRouter>
);

export default Routes;
