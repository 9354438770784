export const CommonTheme = {
  purple: '#b388ff',
  blue: '#80d8ff',
  darkBlue: '#304ffe',
  green: '#00c853',
  lightYellow: '#fff9c4',
  yellow: '#feed89',
  red: '#ff899b',
  orange: '#FF665A',  

  dark1: '#172b4d',
  dark2: '#505f79',
  dark3: '#97a0af',

  light1: '#f4f5f7',
  light2: '#ebecf0',
  light3: '#dfe1e5',
  light4: '#c6c9c9',

  black: '#000000',
  white: '#ffffff',
  transparent: 'rgba(0,0,0,0)',

  breakpoints: {
    zero: 0,
    mobile: 600,
  },
}

export default {
  light: {
    ...CommonTheme,
    text: CommonTheme.black,
    background: CommonTheme.white,
    primary: '#40B0EF',
    link: '#FF665A',
    accent: '#ffdcc7',
    subtitle: CommonTheme.dark3,
    isDark: false,
  },
  dark: {
    ...CommonTheme,
    text: CommonTheme.white,
    background: '#020202',
    primary: '#448aff',
    link: '#314D5D',
    accent: '#ffff8d',
    subtitle: CommonTheme.light3,
    isDark: true,
  }
};
