import React from 'react';
import { useState, useEffect } from 'react';
import { NotionRenderer } from 'react-notion';

import { Link } from "react-router-dom";
import { PageLink, PageWrapper } from "constants/Mixins";

// For styling markdown content
import 'react-notion/src/styles.css';
import './writing.css';

function RiskAversion() {
  const [data, setData] = useState({});

  useEffect(() => {
    document.title = "clarity and drive - writing";
  }, []);

  useEffect(() => {
    fetch(
      'https://notion-api.splitbee.io/v1/page/29c1718f5e0144fe80ecc92da59cd7c8'
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText)
        }
        return res.json();
      })
      .then((data) => setData(data))
      .catch(err => {
        console.log(err)
      });
  }, []);

  return (
    <PageWrapper className='writing'>
      <div className="writing">
        <Link to="/writing">
          <PageLink>&#8592; Writing</PageLink>
        </Link>
        <h1>clarity and drive</h1>
        <h4>finding the roots of what makes you tick</h4>
        <p>2022-11-09</p>
        <NotionRenderer blockMap={data} />
      </div>
    </PageWrapper>

  );
}

export default RiskAversion;
