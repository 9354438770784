import React from "react";
import styled from "styled-components";

import { PageLink } from "constants/Mixins";

type ProjectProps = {
	title: string;
	description: string;
	tags: string[];
	tagColors: string[];
	readUrl: string;
	margin?: string;
};

const Project = ({
	title,
	description,
	tags = [],
	tagColors = [],
	readUrl,
	margin = "18px 0 0 0",
}: ProjectProps) => (
	<ProjectWrapper
		margin={margin}
		style={{
			border: "1.5px solid #D3D3D3",
			borderRadius: "8px",
			padding: "30px",
		}}
	>
		<LeftColumn style={{ marginRight: "60px" }}>
			<Title>{title}</Title>
			<Tags>
				{tags.map((tag, idx) => (
					<Tag key={idx} color={tagColors[idx]}>
						{tag}
					</Tag>
				))}
			</Tags>
		</LeftColumn>
		<RightColumn>
			<Description>{description}</Description>
			<Icons>
				<Icon>
					<PageLink href={readUrl} rel="noopener noreferrer">
						Read
						<PageLink> &#8594;</PageLink>
					</PageLink>
				</Icon>
			</Icons>
		</RightColumn>
	</ProjectWrapper>
);

export default Project;

const ProjectWrapper = styled.div<{ margin: string }>`
	margin: ${({ margin }) => margin};
	display: flex;
	flex-direction: row;

	@media only screen and (max-width: ${({ theme }) =>
			theme.breakpoints.mobile}px) {
		flex-direction: column;
	}
`;

const Title = styled.div`
	display: flex;
	font-weight: 700;
	color: ${({ theme }) => theme.text};
	margin-bottom: 16px;
`;

const Tags = styled.div`
	width: 180px;

	@media only screen and (max-width: ${({ theme }) =>
			theme.breakpoints.mobile}px) {
		width: 100%;
		margin-bottom: 16px;
	}
`;

const Tag = styled.div<{ color: string }>`
	position: relative;
	font-size: 14px;
	font-weight: 400;
	display: inline-block;
	padding: 2px 4px;
	max-width: fit-content;
	color: ${({ theme }) => theme.dark1};
	background-color: ${({ color }) => color};
	border-radius: 4px;
	white-space: nowrap;
	margin-right: 4px;
	margin-bottom: 4px;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: ${({ theme }) => theme.white}80;
		z-index: -1;
	}
`;

const Description = styled.div`
	color: ${({ theme }) => theme.text};
	margin-bottom: 16px;
`;

const LeftColumn = styled.div`
	width: 40%;
	display: flex;
	flex-direction: column;
`;
const RightColumn = styled.div`
	width: 60%;
	display: flex;
	flex-direction: column;
`;

const Icons = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const Icon = styled.div`
	margin-right: 24px;
	align-items: center;
	display: flex;

	svg {
		margin-right: 4px;
	}

	@media only screen and (max-width: ${({ theme }) =>
			theme.breakpoints.mobile}px) {
		margin-bottom: 8px;
	}
`;
