/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { PageContent, PageLink, PageWrapper } from "constants/Mixins";
import styled from "styled-components";

const NotFoundPage = () => {
	useEffect(() => {
		document.title = "Arav Watwani";
	}, []);

	return (
		<PageWrapper>
			<PageContent>
				<Text>This page doesn't exist!</Text>
				<Link to="/">
					<PageLink>&#8592; Home</PageLink>
				</Link>
			</PageContent>
		</PageWrapper>
	);
};

export default NotFoundPage;

const Text = styled.div`
	font-size: 32px;
	font-weight: 700;
	margin-top: 120px;
	margin-bottom: 40px;
	color: #ff665a;
`;
