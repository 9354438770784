/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useState, useEffect } from "react";
import { NotionRenderer } from "react-notion";

import { Link } from "react-router-dom";
import { PageLink, PageWrapper } from "constants/Mixins";

// For styling markdown content
import "react-notion/src/styles.css";
import "./writing.css";

export default function RiskAversion() {
	const [data, setData] = useState({});

	useEffect(() => {
		document.title = "risk aversion - writing";
	}, []);

	useEffect(() => {
		fetch(
			"https://notion-api.splitbee.io/v1/page/2d7a76e151164833a52abb2d6c484204"
		)
			.then((res) => {
				if (!res.ok) {
					throw new Error(res.statusText);
				}
				return res.json();
			})
			.then((data) => setData(data))
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<PageWrapper className="writing">
			<div className="writing">
				<Link to="/writing">
					<PageLink>&#8592; Writing</PageLink>
				</Link>
				<h1>risk aversion</h1>
				<h4>
					thoughts preceding big life decisions shouldn't be as
					draining as you think
				</h4>
				<p>2022-02-11</p>
				<NotionRenderer blockMap={data} />
			</div>
		</PageWrapper>
	);
}
