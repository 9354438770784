import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { PageContent, PageLink, PageWrapper } from "constants/Mixins";
import styled from "styled-components";
import Project from "components/Project";

const WritingPage = () => {
	useEffect(() => {
		document.title = "writing";
	}, []);

	return (
		<PageWrapper>
			<PageContent>
				<Section>
					<Title>Writing</Title>
					<Project
						title={"clarity and drive"}
						description={"finding the roots of what makes you tick"}
						tags={["2022-11-09"]}
						tagColors={["orange"]}
						readUrl={"/writing/clarity-and-drive"}
					/>
					<Project
						title={"risk aversion"}
						description={
							"thoughts preceding big life decisions shouldn't be as draining as you think"
						}
						tags={["2022-02-11"]}
						tagColors={["orange"]}
						readUrl={"/writing/risk-aversion"}
					/>
				</Section>
				<Section>
					<Link to="/">
						<PageLink>&#8592; Back to home</PageLink>
					</Link>
				</Section>
			</PageContent>
		</PageWrapper>
	);
};

export default WritingPage;

const Title = styled.div`
	font-size: 32px;
	margin-top: 120px;
	margin-bottom: 40px;
	font-weight: 700;
	color: #ff665a;
`;

const Section = styled.div`
	margin-bottom: 70px;
`;
